import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { H, Wrapper, Grid } from '@farewill/ui'
import DefaultLayout from 'layouts/DefaultLayout'
import { normalizeArray } from 'lib/graphql/normalize'
import Card from './Card'

const biographyTruncate = (biography) =>
  biography?.length > 120 ? `${biography.slice(0, 120)}...` : biography

const AuthorList = ({ data }) => {
  const authors = normalizeArray(data.authors)
  return (
    <DefaultLayout title="Authors">
      <Wrapper container>
        <H size="XL" decorative>
          Authors
        </H>
      </Wrapper>
      <Grid container containerPaddingTop={0}>
        {authors.map((author) => (
          <Grid.Item key={author.id} spanFromL={4} spanFromM={6}>
            <Card
              name={author.name}
              slug={author.slug}
              imageSrc={author?.image?.file?.url}
              description={biographyTruncate(author?.biography?.biography)}
            />
          </Grid.Item>
        ))}
      </Grid>
    </DefaultLayout>
  )
}

AuthorList.propTypes = {
  data: PropTypes.shape({
    authors: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  }).isRequired,
}

export const query = graphql`
  query {
    authors: allContentfulTeamMember(
      sort: { fields: [fields___articleCount], order: DESC }
      filter: { fields: { articleCount: { gt: 0 } } }
    ) {
      edges {
        node {
          name
          id
          slug
          image {
            file {
              url
            }
          }
          biography {
            biography
          }
        }
      }
    }
  }
`

export default AuthorList
