import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { H, P } from '@farewill/ui'
import Avatar from 'components/Avatar'

import PATHS from 'paths'

const StyledHeader = styled(H)``

const StyledCard = styled(Link)`
  display: block;
  text-decoration: none;

  &:hover {
    text-decoration: none;

    ${StyledHeader} {
      text-decoration: underline;
    }
  }
`

const Card = ({ name, imageSrc, description, slug }) => (
  <StyledCard to={`${PATHS.GENERAL.AUTHORS}/${slug}`}>
    <Avatar imageSrc={imageSrc} size={90} alt={`Photo of ${name}`} />
    <StyledHeader margin={['M', 0, 'XS']} size="S">
      {name}
    </StyledHeader>
    <P>{description}</P>
  </StyledCard>
)

Card.propTypes = {
  name: PropTypes.string.isRequired,
  imageSrc: PropTypes.string,
  description: PropTypes.string,
  slug: PropTypes.string.isRequired,
}

Card.defaultProps = {
  description: '',
  imageSrc: null,
}

export default Card
